@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Paprika&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Outfit", sans-serif;
}

ul {
  list-style: none;
}

h1 {
  font-size: 53px;
  font-family: "Paprika", system-ui;
}

p {
  opacity: 0.9;
  color: white;
}

.black-shadow {
  text-shadow: 1px 3px 2px black;
}

.p-white {
  opacity: 0.70;
  color: white;
}

.li-white {
  opacity: 0.65;
  color: white;
}

.btn {
  background: white;
  opacity: 0.7;
  color: black;
}

.gradient,
.active {
  color: white;
  font-weight: 600;
}

.active {
  color: white;
}

.bg-clear {
  background-color: #eeeeee;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-secondary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .section {
    @apply py-12 lg:py-24 lg:h-screen flex items-center;
  }
}
